import ioClient from "socket.io-client";
import sailsDist, { SocketOptions, SailsSocket } from "sails.io.js-dist";

const enum Tokens {
  ACCESS = "access_token",
  REFRESH = "refresh_token",
}

export enum Events {
  CONNECT = "connect",
  LEAD_BATCH_CREATE = "leadBatchCreate",
}

interface SocketArgs {
  url?: string;
  options?: SocketOptions;
}

const io = sailsDist(ioClient);
io.sails.autoConnect = false;

/**
 * Get Authentication headers for the Sails
 * socket.
 * @returns {SocketOptions}
 */
export function socketAuthQuery() {
  const token = localStorage.getItem(Tokens.ACCESS);
  return {
    query: `token=${token}`,
  };
}

/**
 * Creates a new sails.io socket
 * @param {SocketArgs} [args]
 * @returns {SailsSocket}
 */
export function socketFactory({
  url = process.env.VUE_APP_BACK,
  options = {},
}: SocketArgs): SailsSocket {
  // eslint-disable-next-line
  return io.sails.connect(url!, options);
}
