
import { Component, Vue } from "vue-property-decorator";
import * as socketUtils from "@/utils/sockets";
import * as Progress from "@/utils/progress";
import * as LeadAPI from "@/api/helpers/Lead";
import Notify from "@/utils/notifications";
import { SailsSocket } from "sails.io.js-dist";
import _ from "underscore";
import * as CSV from "csv-string";
import * as SystemServices from "@/api/helpers/System";
import System from "@/models/System";

type FileObject = Record<string, number | string | File>;

interface BarObject extends Progress.ProgressObject {
  variant: string;
}

const onSocketConnect = (
  socket: SailsSocket,
  formData: FormData,
  cb: Function
) => async () => {
  try {
    const resp = await LeadAPI.batchCreate(socket._raw.id, formData);
    if (resp.status === 200) {
      cb(null, resp);
    }
  } catch (err) {
    cb(err, null);
  }
};

@Component
export default class LeadUpload extends Vue {
  fullscreenLoading = false;
  systemLoading = false;
  currentStep = 1;
  activeSystems: Array<System> = [];
  systemHeaders = ["system", "System", "SYSTEM"];
  fileList: Array<FileObject> = [];
  fileJson: any = {
    name: "",
    size: 0,
    lines: 0,
    headers: [],
    content: [],
    separator: "",
  };
  bars: Array<BarObject> = [];
  socket?: SailsSocket;
  barsMapper: Map<string, number> = new Map();
  uploadErrorItems: Array<Progress.ErrorObject> = [];
  systemId: number | null = null;
  hasSystem = false;
  uploading = false;
  translate = {
    Write: "Escribiendo en base de datos",
    "Add to queue": "Encolando peticiones",
    Validation: "Validando datos",
    Upload: "Envíando información",
    "Duplicate Entity": "Elemento duplicado.",
    "Null Value": "Valor nulo no permitido.",
    "Invalid Type": "Tipo de dato inválido.",
    "Invalid Parameter": "Parámetro inválido.",
  };

  get uploadErrors(): Array<Progress.ErrorObject> {
    return this.uploadErrorItems;
  }

  get barsObjects(): Array<BarObject> {
    return this.bars;
  }

  getBarObject(progressValue: string): BarObject {
    const progressObject: Progress.ProgressObject = JSON.parse(progressValue);
    const barObj = progressObject as BarObject;
    barObj.variant = Progress.Variants.INFO;
    if (barObj.status === Progress.Status.COMPLETED) {
      barObj.variant = Progress.Variants.SUCCESS;
    } else if (barObj.status == Progress.Status.FAILED) {
      barObj.variant = Progress.Variants.DANGER;
    }
    return barObj;
  }

  addBarObject(obj: BarObject) {
    if (this.barsMapper.has(obj.phase)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const index = this.barsMapper.get(obj.phase)!;
      this.bars[index] = obj;
    } else {
      const index = this.bars.push(obj) - 1;
      this.barsMapper.set(obj.phase, index);
    }
    if (
      obj.errors !== null &&
      Array.isArray(obj.errors) &&
      obj.errors.length > 0
    ) {
      for (const e of obj.errors) {
        this.uploadErrorItems.push(e);
      }
      console.log(this.uploadErrors);
    }
  }

  getSocket(): SailsSocket {
    return socketUtils.socketFactory({
      options: socketUtils.socketAuthQuery(),
    });
  }

  buildFormData(file: any): FormData {
    const formData = new FormData();
    if (!this.hasSystem && this.systemId)
      formData.append("system", this.systemId.toString());
    formData.append("file", file.raw);
    return formData;
  }

  getFirstLines() {
    this.fullscreenLoading = true;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const text = CSV.parse(e.target.result);
      if (text.length - 1 <= 0) {
        Notify.warning(`El archivo "${this.fileList[0].name}" está vacío.`);
        this.clearFiles();
        return;
      }
      const firstLines = _.first(_.rest(text), 10);
      const objectLines = _.map(firstLines, l => {
        return _.object(_.range(l.length), l);
      });
      this.fileJson = {
        name: this.fileList[0].name,
        size: this.fileList[0].size,
        lines: text.length - 1,
        headers: text[0],
        content: objectLines,
        separator: CSV.detect(text[0].toString()),
      };

      if (_.intersection(text[0], this.systemHeaders).length) {
        this.hasSystem = true;
      } else {
        this.hasSystem = false;
        this.getSystems();
      }
      this.fullscreenLoading = false;
    };
    reader.readAsText(this.fileList[0].raw as File);
  }

  clearFiles() {
    this.fullscreenLoading = true;
    this.fileList = [];
    (this.$refs.upload as HTMLFormElement).clearFiles();
    this.currentStep = 1;
    this.fileJson = {
      name: "",
      size: 0,
      lines: 0,
      headers: [],
      content: [],
      separator: "",
    };
    this.fullscreenLoading = false;
    this.clearProgress();
  }

  clearProgress() {
    this.fullscreenLoading = true;
    this.bars = [];
    this.uploading = false;
    this.barsMapper = new Map();
    this.uploadErrorItems = [];
    this.fullscreenLoading = false;
  }

  async uploadFile() {
    this.clearProgress();
    this.uploading = true;
    this.fullscreenLoading = true;
    const formData = this.buildFormData(this.fileList[0]);
    this.socket = this.getSocket();
    const _onSocketConnect = onSocketConnect(
      this.socket,
      formData,
      (
        err: any,
        resp: {
          data: {
            message: string;
          };
        }
      ) => {
        if (err) {
          Notify.gebServerError(err);
          this.socket?._raw.close();
        } else {
          Notify.successful(resp.data.message);
          this.currentStep = 3;

          this.socket?.on(socketUtils.Events.LEAD_BATCH_CREATE, (x: string) => {
            this.fullscreenLoading = false;
            const barObj = this.getBarObject(x);
            this.addBarObject(barObj);
            if (barObj.status === Progress.Status.FAILED) {
              this.socket?._raw.close();
            } else if (
              barObj.phase === Progress.Phases.WRITE &&
              barObj.status === Progress.Status.COMPLETED
            ) {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const index = this.barsMapper.get(Progress.Phases.WRITE)!;
              this.bars.splice(index, 1);
              this.socket?._raw.close();
              this.bars.push(barObj);
              this.uploading = false;
            }
          });
        }
      }
    );
    this.socket?.on(socketUtils.Events.CONNECT, _onSocketConnect);
    this.fullscreenLoading = false;
  }

  sortErrors(params: any) {
    const DESCENDING = "descending";
    this.uploadErrors.sort((a: any, b: any) => {
      let valueA = a[params.prop] ? a[params.prop] : a.details[0][params.prop];
      let valueB = b[params.prop] ? b[params.prop] : b.details[0][params.prop];
      if (params.order == DESCENDING) {
        const handle = valueB;
        valueB = valueA;
        valueA = handle;
      }
      if (valueA > valueB) {
        return 1;
      }
      if (valueA < valueB) {
        return -1;
      }
      return 0;
    });
  }

  handlefileList(file: FileObject, fileList: Array<FileObject>) {
    this.clearProgress();
    this.currentStep = 2;
    this.fileList = fileList;
    this.getFirstLines();
  }

  getSystems() {
    this.systemLoading = true;
    SystemServices.find({
      order: "name:asc",
      active: 1,
    })
      .then(res => {
        this.activeSystems = res.data.data;
        this.systemId = this.activeSystems[0].id || null;
        this.systemLoading = false;
      })
      .catch(error => {
        Notify.gebServerError(error);
      });
  }

  created() {
    const index = "3.2";
    const title = "Carga de leads";
    this.$store.commit("updateCurrentMenuIndex", index);
    this.$store.commit("updateCurrentTitle", title);
    this.$store.commit("updateBreadcrumbItems", [
      {
        text: "Archivos",
        to: {
          name: "file",
        },
      },
      {
        text: title,
      },
    ]);
  }

  beforeDestroy() {
    if (this.socket) {
      this.socket?._raw.close();
    }
  }
}
